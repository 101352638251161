/* eslint-disable import/prefer-default-export */
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  color: {
    laundrygo: '#d0ff00',
    laundrygoLine: '#d1fe5c',
    newLaundrygo: '#0ac290',
    newLaundrygoBg: '#cef3e9',
    white: '#ffffff',
    snow: '#fafafa',
    lightGray: '#f1f1f1',
    snowGray: '#eeeeee',
    platinum: '#e1e1e1',
    pastelGray: '#cccccc',
    sliver: '#a1a1a1',
    gray: '#757575',
    darkGray: '#444444',
    dimBlack: '#212121',
    bgDimBlack: 'rgba(33, 33, 33, 0.8)',
    positive: '#327fdb',
    negative: '#ec5f5f',
    jasmine: '#ffdf77',
    paleCopper: '#e09866',
    black: '#000000',
    manatee: '#979797',
    gray1: '#eeeeee',
    gray2: '#d9d9d9',
    red: '#ec5f5f',
    paleRed: '#ff4f17',
    orange01: '#ffe5dc',
    blue: '#327fdb',
    gray01: '#fafafa',
    gray02: '#f5f5f5',
    gray03: '#efefef',
    gray04: '#c9c9c9',
    gray05: '#939393',
    gray300: '#EFEFEF',
    gray500: '#C9C9C9',
    gray700: '#939393',
    coolGray100: '#F4F5F7',
    coolGray300: '#E5E8ED',
    coolGray700: '#646D7A',
    coolGray800: '#4C5059',
  },
  hexOpacitySuffix: {
    0: '00',
    5: '0C',
    10: '19',
    15: '26',
    20: '33',
    25: '3F',
    30: '4C',
    35: '59',
    40: '66',
    45: '72',
    50: '7F',
    55: '8C',
    60: '99',
    65: 'A5',
    70: 'B2',
    75: 'BF',
    80: 'CC',
    85: 'D8',
    90: 'E5',
    95: 'F2',
    100: 'FF',
  },
};

export { theme };
