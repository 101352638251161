import getConfig from 'next/config';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies, { CookieGetOptions, CookieSetOptions } from 'universal-cookie';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { fullPagePush, pagePush } from '~/utils/postMessage';
import { ERROR_TYPE_TOAST } from '~/components/store/storeMessage';
import { TMaxCount } from '~/components/store/types';
import CONSTANT, {
  NON_AUTHENTICATION_API_LIST,
  AUTHENTICATION_API_URL_LIST,
} from '~/utils/constant';

const { publicRuntimeConfig } = getConfig();
const { V2_API } = publicRuntimeConfig;

const MySwal = withReactContent(Swal);
const cookies = new Cookies();

// eslint-disable-next-line import/prefer-default-export
export const sweetAlert = (
  title: string,
  type: 'error' | 'success' | 'warning' | 'info' | 'question' | undefined = 'error'
) => {
  MySwal.fire({
    icon: type,
    title,
  });
};

// 숫자 콤마 찍어주기
export const commaNumber = (value: string | number) => {
  let changeValue = value;

  if (typeof changeValue === 'string') {
    changeValue = Number(value);
  }

  return changeValue.toLocaleString('ko-KR');
};

// 원 처리
export const commaNumberWon = (value: string | number) => {
  const commaAppliedNum = commaNumber(value);
  return `${commaAppliedNum}원`;
};

// \n을 개행 처리
export const contentLine = (value) => {
  return value ? value.replace(/(?:\r\n|\r|\n)/g, '<br />') : value;
};

// a tag href 파싱
export const contentReg = (value, name) => {
  let result;
  if (value) {
    result = value;
    const expression = /href="(.*?)"/gi;
    result = result.replace(expression, (_$0, $1) => {
      return `href="laundrygo://openweb?name=${encodeURIComponent(name)}&url=${encodeURIComponent(
        $1
      )}"`;
    });
  }

  return result || value;
};

/**
 * url query 를 object 로 변환
 * @param query <string> 변환 할 string
 * @returns <object>
 */
export const getObjectFromQuery = (query) => {
  const targetQuery = decodeURIComponent(query)
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"');

  return JSON.parse(`{"${targetQuery}"}`);
};

/**
 * 스토어 배너 openweb 일 때 params object 얻기
 * @param url <string>
 * @returns <object>
 */
export const getBannerUrl = (url) => {
  const targetUrl = url
    .replace('laundrygo://openweb?', '')
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"');

  return JSON.parse(`{"${targetUrl}"}`);
};

/**
 * 할인된 가격 얻기
 * @param originalPrice <number> 할인 전 가격
 * @param discountRate  <number> 할인율
 * @returns <number>
 */
export const getDiscountSellPrice = (originalPrice: number, discountRate: number) => {
  return Number((originalPrice * ((100 - discountRate) / 100)).toFixed(0));
};

/**
 * 할인율 얻기
 * @param originalPrice <number> 할인 전 가격
 * @param sellPrice <number> 할인 후 가격
 * @returns <number>
 */
export const getDiscountRate = (originalPrice: number, sellPrice: number) => {
  return Number((100 - (sellPrice / originalPrice) * 100).toFixed(0));
};

/**
 * 할인 전 가격 얻기
 * @param sellPrice <number> 할인 후 가격
 * @param discountRate <number> 할인율
 * @returns <number>
 */
export const getOriginalPrice = (sellPrice: number, discountRate: number) => {
  return Number(((sellPrice / (100 - discountRate)) * 100).toFixed(0));
};

/**
 * 빈 객체인지 확인
 * @param obj <object> 확인할 객체
 * @returns <boolean>
 */
export const getIsEmptyObj = (obj) => {
  return Object.keys(obj).length === 0;
};

/**
 * v2에서 사용하는 인증 관련 header 설정
 * @param headers <object> next 의 req 중 header 값
 * @returns <object>
 */
export const getV2AuthHeaders = (headers, isNeedAppVersion = false, appVersion?) => {
  const authHeader = {
    [CONSTANT.HEADERS.HEADER_ACCESS_TOKEN]: headers[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN],
    'muser-agent': headers['muser-agent'], // BE에서 확인하지는 않음
  };

  if (isNeedAppVersion) {
    authHeader[CONSTANT.HEADERS.HEADER_APP_VERSION] =
      headers[CONSTANT.COOKIE.APP_VERSION] || appVersion;
  }

  return authHeader;
};

/**
 * 적립 포인트 얻기
 * @param price <number> 제품 개당 가격
 * @param rate <number> 적립률
 * @returns <number>
 */
export const getAccmulatePoint = (price, rate) => {
  return Math.floor(price * (rate / 100));
};

/**
 * 추가금액을 기호 붙여서 리턴
 * @param price <number> additionalPrice
 * @returns <string>
 */
export const getAdditionalPrice = (price) => {
  const additionalPriceSymbol = price > 0 ? '+' : '-';
  return `${additionalPriceSymbol}${commaNumber(Math.abs(price).toFixed(0))}`;
};

export const getSkusSellPrice = (skus) => {
  return skus.reduce((acc, curr) => {
    return acc + curr.sellPrice;
  }, 0);
};

/**
 * 구매 가능 최대 수량 계산
 * @purchaseLimitCount : 구매 제한 수량
 * @orderPossibleQuantity : 구매 가능 재고 수량
 * @returns <object>
 *  maxType: STOCK(재고수량) | LIMIT(제한수량)
 *  maxCount: <number>
 */
export const getOrderMaxCount = ({
  purchaseLimitCount,
  orderPossibleQuantity,
}: {
  purchaseLimitCount: number;
  orderPossibleQuantity: number;
}): TMaxCount => {
  if (purchaseLimitCount === 0 || purchaseLimitCount >= orderPossibleQuantity) {
    return {
      maxType: 'STOCK',
      maxCount: orderPossibleQuantity,
    };
  }

  return {
    maxType: 'LIMIT',
    maxCount: purchaseLimitCount,
  };
};

export const getBrazeCartOrderAllObject = ({
  type,
  dataArray,
  brazeMatchingKeys,
  totalQuantity,
  totalSellPrice,
}: {
  type: 'CART' | 'ORDER';
  dataArray: any[];
  brazeMatchingKeys: {
    [key: string]: string;
  };
  totalQuantity: number;
  totalSellPrice: number;
}) => {
  const initValues = {
    item_id: '',
    item_name: '',
    item_category: '',
    item_price: '',
    item_tag: '',
    item_image_url: '',
    item_brand_name: '',
  };
  const tagTempArr: string[] = [];

  const brazeEventValues = dataArray.reduce((acc, curr) => {
    const rValues = initValues;

    Object.keys(acc).forEach((key) => {
      const targetKey = brazeMatchingKeys[key];
      let currentValue = curr[targetKey];

      switch (key) {
        case 'item_name':
          currentValue = currentValue.replace(/,/g, ' ');
          break;
        case 'item_price':
          if (type === 'CART') {
            currentValue = curr.productSkus.reduce((skuAcc, skuCurr) => {
              return skuAcc + skuCurr.sellPrice;
            }, 0);
          } else if (type === 'ORDER') {
            currentValue = curr.sellPrice / curr.quantity;
          }
          break;
        case 'item_image_url':
          currentValue = currentValue[0] as string[];
          break;
        case 'item_tag':
          currentValue.forEach((tag) => {
            if (tagTempArr.indexOf(tag) === -1) {
              tagTempArr.push(tag);
            }
          });
          break;
        default:
          break;
      }

      if (key !== 'item_tag') {
        rValues[key] = acc[key] ? `${acc[key]},${currentValue}` : `${currentValue}`;
      }
    });

    return rValues;
  }, initValues);

  brazeEventValues.total_number_of_item = totalQuantity;
  brazeEventValues.total_item_price = totalSellPrice;
  brazeEventValues.item_tag = tagTempArr.join(',');

  return brazeEventValues;
};

/**
 * 고객 동의 답변 제한 시간 텍스트 노출 형식에 맞게 변환하여 리턴
 * @param responseLimitDateTime 답변 제한 시간
 * @param isIncludeDate <boolean> 년월일 표기 필요한지 여부
 * @returns '00년 00월 00일 오전 00시 00분' 또는 '오전 00시 00분' 형식
 */
export const getResponseLimitDateTimeStr = ({
  responseLimitDateTime,
  isIncludeDate,
}: {
  responseLimitDateTime: string;
  isIncludeDate: boolean;
}) => {
  const [date, time] = responseLimitDateTime.split('T');
  const [year, month, day] = date.split('-');
  const [hour, min] = time.split(':');
  const timeStr = +hour < 12 ? '오전' : '오후';
  const newHour = +hour > 12 ? +hour - 12 : +hour;

  return `${
    isIncludeDate ? `${year.slice(2)}년 ${month}월 ${day}일 ` : ''
  }${timeStr} ${newHour}시 ${min}분`;
};

/**
 * 서버 응답 error 를 showToast 로 띄우는 error 인지 체크
 * @param errorMessage <string> : error.message
 * @returns boolean
 */
export const getIsErrorShowToast: (errorMessage: string) => boolean = (errorMessage) => {
  return ERROR_TYPE_TOAST.indexOf(errorMessage) > -1;
};

/**
 * 현재 앱 버전이 최신인지 아닌지 체크
 * @param <object>
 *  - currentVersion : 현재 앱 버전 '1.5.9'
 *  - newVersion : 새로운 앱 버전 '1.6.0'
 * @returns Y | N
 */
export const getNewAppVersionYn = ({ currentVersion, newVersion }) => {
  if (currentVersion === null) {
    return 'N';
  }

  const currentAppVersionSplit = currentVersion.split('.').map((cn) => cn * 1);
  const newAppVersionSplit = newVersion.split('.').map((nn) => nn * 1);

  return (currentAppVersionSplit[0] === newAppVersionSplit[0] &&
    currentAppVersionSplit[1] >= newAppVersionSplit[1]) ||
    currentAppVersionSplit[0] >= newAppVersionSplit[0] + 1
    ? 'Y'
    : 'N';
};

/**
 * 버전 비교
 * @param
 *  - currentVersion : 현재 앱 버전 '1.5.9'
 *  - newVersion : 새로운 앱 버전 '1.6.0'
 * @returns Y | N
 */
export const isOlderVersion = (currentVersion: string, compareVersion: string) => {
  const currentVersionSplit = currentVersion.split('.').map((item) => parseInt(item, 10));
  const compareVersionSplit = compareVersion.split('.').map((item) => parseInt(item, 10));

  for (let i = 0; i < currentVersionSplit.length; i += 1) {
    if (currentVersionSplit[i] < compareVersionSplit[i]) {
      return true;
    }
    if (currentVersionSplit[i] > compareVersionSplit[i]) {
      return false;
    }
  }
  return false;
};

/**
 * AOS이고, 낮은 앱 버전일 때 fullPagePush 대신 pagePush 사용
 * (안드로이드 구 버전은 app 내 웹뷰에서 fullPagePush 기능 미제공)
 * @param <object>
 *  - url : 페이지 url
 *  - title : pagePush 인 경우 보여질 헤더 타이틀
 *  - pid : fullPagePush 일 때 페이지 id
 *  - appVersion : 현재 앱 버전 '1.6.0'
 */
export const fullPagePushByOsAppVersion = ({ url, title, pid, appVersion }) => {
  const isAndroidOldAppVersion =
    window?.Android &&
    getNewAppVersionYn({
      currentVersion: appVersion ?? '1.6.0',
      newVersion: '1.7.0',
    }) === 'N';

  if (isAndroidOldAppVersion) {
    pagePush(`${url}?showHeaderYn=N`, title); // showHeaderYn=N으로 헤더 처리
  } else {
    fullPagePush(pid, url);
  }
};

/**
 * Cookie
 */
export const cookie = {
  getAll: (options?: CookieGetOptions) => {
    return cookies.getAll(options);
  },
  get: (name: string, options?: CookieGetOptions) => {
    return cookies.get(name, options);
  },
  set: (name: string, value: string | any, options?: CookieSetOptions) => {
    return cookies.set(name, value, options);
  },
  remove: (name: string, options?: CookieSetOptions) => {
    return cookies.remove(name, options);
  },
};

export const getAxiosErrorInfo = (error) => {
  if (error.isAxiosError) {
    const { config, code, response } = error as AxiosError;
    const errorInfo = {
      method: config.method,
      url: config.url,
      code,
      status: response?.status,
      data: response?.data,
      header: response?.headers,
    };

    return errorInfo;
  }

  return null;
};

export const isNonAuthenticationRequest = (request: AxiosRequestConfig) => {
  if (!request.method || !request.url) {
    return false;
  }

  const { method: requestMethod } = request;
  let requestUrl = request.url;

  if (request.url.startsWith(V2_API)) {
    requestUrl = request.url.replace(V2_API, '');
  }

  const isAuthenticationRequest = AUTHENTICATION_API_URL_LIST.some((apiUrl) =>
    requestUrl.includes(apiUrl)
  );

  if (isAuthenticationRequest) {
    return false;
  }

  const target = NON_AUTHENTICATION_API_LIST.find((nonAuthenticationApi) => {
    const isSameMethod = nonAuthenticationApi.method === requestMethod.toLowerCase();

    if (nonAuthenticationApi.exact) {
      return isSameMethod && nonAuthenticationApi.url === requestUrl;
    }

    if (!nonAuthenticationApi.exact) {
      const haveRequestVariables = nonAuthenticationApi.url.includes(':');
      let nonAuthenticationApiUrl = nonAuthenticationApi.url;

      if (haveRequestVariables) {
        const requestPaths = requestUrl.split('/');
        const requestVariables = requestPaths[requestPaths.length - 1];
        nonAuthenticationApiUrl = nonAuthenticationApi.url.replace(
          /:[a-zA-Z0-9]+/g,
          requestVariables
        );
      }

      return isSameMethod && requestUrl.includes(nonAuthenticationApiUrl);
    }
  });

  return target !== undefined;
};

export const isObject = (val: any) => {
  return val && typeof val === 'object';
};

export const isURLSearchParams = (val: any) => {
  return toString.call(val) === '[object URLSearchParams]';
};

export const isArrayBufferView = (val: any) => {
  let result: boolean;

  if (typeof ArrayBuffer !== 'undefined' && ArrayBuffer.isView) {
    result = ArrayBuffer.isView(val);
  } else {
    result = val && val.buffer && toString.call(val.buffer) === '[object ArrayBuffer]';
  }
  return result;
};

export const getUserIdFromAccessToken = (accessToken: string) => {
  const decoded = jwtDecode<JwtPayload & { userId: number }>(accessToken);
  return decoded.userId;
};

/**
 *
 * @param date YYYY-MM-DD 형식의 string
 * @returns YYYY년 MM월 DD일
 */
export const formatKoreanDate = (value: string | Date) => {
  const date = typeof value === 'object' ? value : new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}년 ${month}월 ${day}일`;
};
