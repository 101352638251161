import { createAction, handleActions } from 'redux-actions';
import { call, takeLatest, put, all, select } from 'redux-saga/effects';
import produce from 'immer';
import getConfig from 'next/config';
import * as amplitude from '@amplitude/analytics-browser';

import {
  brazeEvent,
  showLoading,
  hideLoading,
  completeAlertCloseAll,
  showToast,
  executeWeb2App,
} from '~/utils/postMessage';
import MESSAGES from '~/components/store/storeMessage';

import fetcher from '~/api/lib/fetcher';
import { HttpError } from '~/types/Error';
import { convertError } from '~/utils/converter';

import {
  watchPossibleOrderFetchSaga,
  watchUserSummaryInfoFetchSaga,
} from '~/redux/modules/store/storeCommon';

import {
  getV2AuthHeaders,
  getAccmulatePoint,
  getSkusSellPrice,
  getBrazeCartOrderAllObject,
  getNewAppVersionYn,
} from '~/utils/common';
import { AMPLITUDE_EVENT_NAME } from '~/utils/constants/amplitude';

const { publicRuntimeConfig } = getConfig();
const { V2_API } = publicRuntimeConfig;

// Actions
const FETCH_CART_LIST_REQUEST = 'FETCH_CART_LIST_REQUEST';
const FETCH_CART_LIST_SUCCESS = 'FETCH_CART_LIST_SUCCESS';
const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';
const FETCH_CART_FAILURE_CLEAR = 'FETCH_CART_FAILURE_CLEAR';
const CART_FETCH_FAILURE = 'CART_FETCH_FAILURE';
const CART_QUANTITY_UPDATE_REQUEST = 'CART_QUANTITY_UPDATE_REQUEST';
const CART_QUANTITY_UPDATE = 'CART_QUANTITY_UPDATE';
const CART_QUANTITY_UPDATE_SUCCESS = 'CART_QUANTITY_UPDATE_SUCCESS';
const CART_ITEM_DELETE_REQUEST = 'CART_ITEM_DELETE_REQUEST';
const CART_ITEM_DELETE = 'CART_ITEM_DELETE';
const CART_ITEM_DELETE_SUCCESS = 'CART_ITEM_DELETE_SUCCESS';
const CART_BUY_NOW_REQUEST = 'CART_BUY_NOW_REQUEST';
const CART_BUY_NOW_SUCCESS = 'CART_BUY_NOW_SUCCESS';

const fetchCartListRequest = createAction(FETCH_CART_LIST_REQUEST);
const fetchCartListSuccess = createAction(FETCH_CART_LIST_SUCCESS);
const fetchCartFailure = createAction(FETCH_CART_FAILURE);
const fetchCartFailureClear = createAction(FETCH_CART_FAILURE_CLEAR);
const cartFetchFailure = createAction(CART_FETCH_FAILURE);
const cartQuantityUpdateRequest = createAction(CART_QUANTITY_UPDATE_REQUEST);
const cartQuantityUpdate = createAction(CART_QUANTITY_UPDATE);
const cartQuantityUpdateSuccess = createAction(CART_QUANTITY_UPDATE_SUCCESS);
const cartItemDeleteRequest = createAction(CART_ITEM_DELETE_REQUEST);
const cartItemDelete = createAction(CART_ITEM_DELETE);
const cartItemDeleteSuccess = createAction(CART_ITEM_DELETE_SUCCESS);
const cartBuyNowRequest = createAction(CART_BUY_NOW_REQUEST);
const cartBuyNowSuccess = createAction(CART_BUY_NOW_SUCCESS);

export {
  fetchCartListRequest,
  cartQuantityUpdateRequest,
  cartItemDeleteRequest,
  cartBuyNowRequest,
  fetchCartFailureClear,
};

interface IInitialState {
  pending: boolean;
  data: any;
  error: any;
}

const initialState: IInitialState = {
  pending: false,
  data: {},
  error: null,
};

export const reducer = handleActions(
  {
    [FETCH_CART_LIST_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.data = initialState.data;
        draft.error = null;
      }),
    [FETCH_CART_LIST_SUCCESS]: (state, { payload: { c, d } }: any) =>
      produce(state, (draft) => {
        if (c > 0) {
          const { message } = d;
          throw new HttpError(c, message);
        }

        draft.pending = true;
        draft.error = null;

        draft.data = {
          ...d,
          totalQuantity: d.skuShoppingBasket.reduce(
            (accumulator, current) =>
              current.buyPossibleYn === 'Y' ? accumulator + current.quantity : accumulator,
            0
          ),
          refinedSkuShoppingBasket: d.skuShoppingBasket.reduce((acc, curr) => {
            const newCurrent = {
              ...curr,
              skusTotalSellPrice: curr.productSkus.reduce((skuAcc, skuCurr) => {
                return skuAcc + skuCurr.sellPrice;
              }, 0),
            };

            if (acc.length === 0) {
              return [
                {
                  productBrandName: newCurrent.productBrandName,
                  list: [newCurrent],
                },
              ];
            }

            const indexByProductBrandName = acc.findIndex(
              (prev) => prev.productBrandName === newCurrent.productBrandName
            );

            if (indexByProductBrandName > -1) {
              acc[indexByProductBrandName].list.push(newCurrent);
              return acc;
            }
            return [
              ...acc,
              {
                productBrandName: newCurrent.productBrandName,
                list: [newCurrent],
              },
            ];
          }, []),
          groupByProductId: d.skuShoppingBasket.reduce((acc, curr) => {
            const indexOfAcc = acc.findIndex((item) => item.productId === curr.productId);

            if (indexOfAcc === -1) {
              const isPurchaseLimitItem = curr.purchaseLimitCount !== 0;

              return [
                ...acc,
                {
                  productId: curr.productId,
                  productIdCount: 1,
                  isPurchaseLimitItem,
                  quantity: curr.quantity,
                  orderPossibleQuantity: curr.orderPossibleQuantity,
                  purchaseLimitCount: curr.purchaseLimitCount,
                  remainingPurchaseLimitCount: isPurchaseLimitItem
                    ? curr.remainingPurchaseLimitCount ?? curr.purchaseLimitCount
                    : 99999,
                },
              ];
            }

            const newAcc = acc.slice();

            newAcc[indexOfAcc] = {
              ...newAcc[indexOfAcc],
              productIdCount: newAcc[indexOfAcc].productIdCount + 1,
              quantity: newAcc[indexOfAcc].quantity + curr.quantity,
            };

            return newAcc;
          }, []),
        };
      }),
    [FETCH_CART_FAILURE]: (state, { payload: error }) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = convertError(error);
      }),
    [FETCH_CART_FAILURE_CLEAR]: (state) =>
      produce(state, (draft) => {
        draft.error = null;
      }),
    [CART_FETCH_FAILURE]: (state, { payload: { prevData, error } }: any) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.data = prevData;
        draft.error = convertError(error);
      }),
    [CART_QUANTITY_UPDATE_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
      }),
    [CART_QUANTITY_UPDATE]: (state, { payload: { basketIndex, itemIndex, newQuantity } }: any) =>
      produce(state, (draft) => {
        const {
          refinedSkuShoppingBasket,
          totalQuantity,
          totalOriginalPrice,
          totalSellPrice,
          totalAccumulatePoint,
          groupByProductId,
        } = draft.data;

        const newRefinedSkuShoppingBasket = refinedSkuShoppingBasket.slice();
        const newList = newRefinedSkuShoppingBasket[basketIndex].list.slice();
        const targetItem = newList[itemIndex];

        if (targetItem.quantity === newQuantity) {
          return;
        }

        const diffCount = newQuantity - targetItem.quantity;
        const newTotalQuantity = totalQuantity + diffCount;

        const newTotalSellPrice = totalSellPrice + targetItem.skusTotalSellPrice * diffCount;

        const sumTotalOriginalPrice =
          totalOriginalPrice +
          Math.max(targetItem.productOriginalPrice, targetItem.skusTotalSellPrice) * diffCount;
        const newTotalOriginalPrice = Math.max(newTotalSellPrice, sumTotalOriginalPrice);

        const newTotalDiscountPrice = newTotalOriginalPrice - newTotalSellPrice;
        const newTotalAccumulatePoint =
          totalAccumulatePoint +
          getAccmulatePoint(targetItem.skusTotalSellPrice, targetItem.productAccumulate) *
            diffCount;

        newList[itemIndex] = { ...targetItem, quantity: newQuantity };

        newRefinedSkuShoppingBasket[basketIndex] = {
          ...newRefinedSkuShoppingBasket[basketIndex],
          list: newList,
        };

        const newGroupByProductId = groupByProductId.slice();
        const targetProductIdIndex = newGroupByProductId.findIndex(
          (item) => item.productId === targetItem.productId
        );

        newGroupByProductId[targetProductIdIndex] = {
          ...newGroupByProductId[targetProductIdIndex],
          quantity: newGroupByProductId[targetProductIdIndex].quantity + diffCount,
        };

        draft.data = {
          ...draft.data,
          skuShoppingBasket: newRefinedSkuShoppingBasket.reduce((acc, curr) => {
            return acc.concat(curr.list);
          }, []),
          totalQuantity: newTotalQuantity,
          totalOriginalPrice: newTotalOriginalPrice,
          totalSellPrice: newTotalSellPrice,
          totalDiscountPrice: newTotalDiscountPrice,
          totalAccumulatePoint: newTotalAccumulatePoint,
          refinedSkuShoppingBasket: newRefinedSkuShoppingBasket,
          groupByProductId: newGroupByProductId,
        };
      }),
    [CART_QUANTITY_UPDATE_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.pending = false;
      }),
    [CART_ITEM_DELETE_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
      }),
    [CART_ITEM_DELETE]: (state, { payload: { basketIndex, basketId, basketItemIndex } }: any) =>
      produce(state, (draft) => {
        const {
          refinedSkuShoppingBasket,
          totalQuantity,
          totalOriginalPrice,
          totalSellPrice,
          totalDiscountPrice,
          totalAccumulatePoint,
          groupByProductId,
        } = draft.data;

        let newRefinedSkuShoppingBasket = refinedSkuShoppingBasket.slice();
        const newList = newRefinedSkuShoppingBasket[basketIndex].list.filter(
          (item) => item.id !== basketId
        );
        const targetItem = newRefinedSkuShoppingBasket[basketIndex].list[basketItemIndex];

        let totalAttr;

        // 구매 불가능 상품은 합계에 포함 안되어 있으므로 구매가능/불가능 나눠서 처리
        if (targetItem.buyPossibleYn === 'Y') {
          const newTotalQuantity = totalQuantity - targetItem.quantity;

          const newTotalSellPrice =
            totalSellPrice - targetItem.skusTotalSellPrice * targetItem.quantity;

          const sumTotalOriginalPrice =
            totalOriginalPrice - targetItem.productOriginalPrice * targetItem.quantity;
          const newTotalOriginalPrice = Math.max(newTotalSellPrice, sumTotalOriginalPrice);

          const newTotalDiscountPrice = newTotalOriginalPrice - newTotalSellPrice;
          const newTotalAccumulatePoint =
            totalAccumulatePoint -
            getAccmulatePoint(targetItem.skusTotalSellPrice, targetItem.productAccumulate) *
              targetItem.quantity;

          totalAttr = {
            totalQuantity: newTotalQuantity,
            totalOriginalPrice: newTotalOriginalPrice,
            totalSellPrice: newTotalSellPrice,
            totalDiscountPrice: newTotalDiscountPrice,
            totalAccumulatePoint: newTotalAccumulatePoint,
          };
        } else {
          totalAttr = {
            totalQuantity,
            totalOriginalPrice,
            totalSellPrice,
            totalDiscountPrice,
            totalAccumulatePoint,
          };
        }

        if (newList.length === 0) {
          newRefinedSkuShoppingBasket = newRefinedSkuShoppingBasket.filter(
            (_basket, rBasketIndex) => rBasketIndex !== basketIndex
          );
        } else {
          newRefinedSkuShoppingBasket[basketIndex] = {
            ...newRefinedSkuShoppingBasket[basketIndex],
            list: newList,
          };
        }

        const newGroupByProductId = groupByProductId.slice();
        const targetProductIdIndex = newGroupByProductId.findIndex(
          (item) => item.productId === targetItem.productId
        );

        newGroupByProductId[targetProductIdIndex] = {
          ...newGroupByProductId[targetProductIdIndex],
          quantity: newGroupByProductId[targetProductIdIndex].quantity - targetItem.quantity,
        };

        draft.data = {
          ...draft.data,
          ...totalAttr,
          skuShoppingBasket: newRefinedSkuShoppingBasket.reduce((acc, curr) => {
            return acc.concat(curr.list);
          }, []),
          refinedSkuShoppingBasket: newRefinedSkuShoppingBasket,
          groupByProductId: newGroupByProductId,
        };
      }),
    [CART_ITEM_DELETE_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.pending = false;
      }),
    [CART_BUY_NOW_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.error = null;
      }),
    [CART_BUY_NOW_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = null;
      }),
  },
  initialState
);

// 장바구니 목록 가져오기
function* watchFetchCartListSaga() {
  showLoading();

  const { headers } = yield select((state) => state.http);

  try {
    yield* watchUserSummaryInfoFetchSaga(undefined);
    const url = `${V2_API}/v2/products/basket`;
    const result = yield call(fetcher.get, url, {
      headers: {
        ...getV2AuthHeaders(headers),
      },
    });

    yield put(fetchCartListSuccess({ ...result.data }));
  } catch (error) {
    yield put(fetchCartFailure(error));
  } finally {
    hideLoading();
  }
}

// 장바구니 개별 품목 수량 업데이트
function* watchFetchCartCountUpdateSaga({ payload }) {
  const { headers } = yield select((state) => state.http);

  const { basketIndex, itemIndex, newQuantity } = payload;
  const { data } = yield select((state) => state.store.cart);
  const sendDataTarget = data.refinedSkuShoppingBasket[basketIndex].list[itemIndex];

  try {
    if (sendDataTarget.quantity !== newQuantity) {
      yield put(cartQuantityUpdate(payload));
      const url = `${V2_API}/v2/products/basket`;
      yield call(
        fetcher.put,
        url,
        {
          quantity: newQuantity,
          skuShoppingBasketId: sendDataTarget.id,
        },
        {
          headers: {
            ...getV2AuthHeaders(headers),
          },
        }
      );
      yield put(cartQuantityUpdateSuccess());
    }
  } catch (error) {
    yield put(
      cartFetchFailure({
        prevData: data,
        error,
      })
    );
  }
}

// 장바구니 개별 품목 삭제
function* watchFetchCartItemDeleteSaga({ payload }) {
  showLoading();

  const { headers } = yield select((state) => state.http);

  const { basketId } = payload;
  const { data } = yield select((state) => state.store.cart);

  try {
    yield put(cartItemDelete(payload));
    const url = `${V2_API}/v2/products/basket/${basketId}`;
    yield call(fetcher.delete, url, {
      headers: {
        ...getV2AuthHeaders(headers),
      },
    });

    yield put(cartItemDeleteSuccess());
    showToast(MESSAGES.ORDER.CART_DELETE_COMPLETE, 'Y');

    const product = data.skuShoppingBasket.filter((item) => item.id === basketId)[0];
    brazeEvent({
      eventName: 'item_removed_from_cart',
      eventValue: {
        item_id: String(product.id),
        item_name: product.productName,
        item_category: product.categoryName,
        item_price: `${getSkusSellPrice(product.productSkus) * product.quantity}`,
        item_tag: product.tags.join(','),
        item_image_url: product.productRepresentUrls[0],
        item_brand_name: product.productBrandName,
      },
    });

    amplitude.track(AMPLITUDE_EVENT_NAME.STORE.ITEM_REMOVED, {
      item_name:
        product.productSkus.length > 0
          ? `${product.productName}(${product.productSkus[0].displayOptionAttribute})`
          : product.productName,
      item_id: product.id.toString(),
      item_price: `${getSkusSellPrice(product.productSkus) * product.quantity}`,
    });
  } catch (error) {
    yield put(
      cartFetchFailure({
        prevData: data,
        error,
      })
    );
  } finally {
    hideLoading();
  }
}

// 장바구니 결제 성공 개별 삭제
function* deleteBuySuccessItem({ item, headers }) {
  const itemClearUrl = `${V2_API}/v2/products/basket/${item.id}`;
  yield call(fetcher.delete, itemClearUrl, {
    headers: {
      ...getV2AuthHeaders(headers),
    },
  });
}

// 장바구니 전체 결제
function* watchCartBuyNowSaga() {
  showLoading();

  const { headers } = yield select((state) => state.http);

  const storeState = yield select((state) => state.store);
  const { data } = storeState.cart;
  const { skuShoppingBasket, totalSellPrice, totalQuantity } = data;

  const isNewAppVersion =
    getNewAppVersionYn({
      currentVersion: headers['app-version'],
      newVersion: '1.6.0',
    }) === 'Y';

  const availableItems = skuShoppingBasket.filter(
    (sku) => sku.buyPossibleYn === 'Y' && sku.orderPossibleQuantity > 0
  );

  const reqProducts = availableItems.map((item) => ({
    id: item.productId,
    quantity: item.quantity,
    skus: item.productSkus.map((sku) => ({
      productSkuGroupId: sku.productSkuGroupId,
      skuId: sku.skuId,
    })),
  }));

  const hasDisabledItem = skuShoppingBasket.length !== availableItems.length;

  try {
    yield* watchPossibleOrderFetchSaga();
    const { washId, isOrderAvailable } = yield select((state) => state.store.common);

    if (!isOrderAvailable) {
      showToast(MESSAGES.PRODUCT.BUY_TIMEOUT);
      return;
    }

    const url = `${V2_API}/v2/products/order`;
    yield call(
      fetcher.post,
      url,
      {
        products: reqProducts,
        userTotalOrderPrice: totalSellPrice,
        washId,
      },
      {
        headers: {
          ...getV2AuthHeaders(headers),
        },
      }
    );

    if (hasDisabledItem) {
      yield all(
        availableItems.map((item) =>
          call(deleteBuySuccessItem, {
            item,
            headers: getV2AuthHeaders(headers),
          })
        )
      );
    } else {
      const clearUrl = `${V2_API}/v2/products/basket/all`;
      yield call(fetcher.delete, clearUrl, {
        headers: {
          ...getV2AuthHeaders(headers),
        },
      });
    }

    yield put(cartBuyNowSuccess());
    if (isNewAppVersion) {
      executeWeb2App({
        command: 'completeAlertCloseAll',
        values: {
          text: MESSAGES.PRODUCT.BUY_NOW_SUCCESS,
          tabName: 'Laundry',
        },
      });
    } else {
      completeAlertCloseAll(MESSAGES.PRODUCT.BUY_NOW_SUCCESS);
    }

    const brazeMatchingKeys = {
      item_id: 'id',
      item_name: 'productName',
      item_category: 'categoryName',
      item_price: 'productSellPrice',
      item_tag: 'tags',
      item_image_url: 'productRepresentUrls',
      item_brand_name: 'productBrandName',
    };
    const brazeEventValues = getBrazeCartOrderAllObject({
      type: 'CART',
      dataArray: skuShoppingBasket,
      brazeMatchingKeys,
      totalQuantity,
      totalSellPrice,
    });
    brazeEvent({
      eventName: 'item_order_completed',
      eventValue: brazeEventValues,
      purchase: {
        productId: 'store',
        price: totalSellPrice,
      },
    });
  } catch (error) {
    yield put(
      cartFetchFailure({
        prevData: data,
        error,
      })
    );

    if ((error as any).response.data.m === MESSAGES.ORDER.SELL_EXPIRATION) {
      yield* watchFetchCartListSaga();
    }
  } finally {
    hideLoading();
  }
}

export const sagas = [
  takeLatest<any>(FETCH_CART_LIST_REQUEST, watchFetchCartListSaga),
  takeLatest<any>(CART_QUANTITY_UPDATE_REQUEST, watchFetchCartCountUpdateSaga),
  takeLatest<any>(CART_ITEM_DELETE_REQUEST, watchFetchCartItemDeleteSaga),
  takeLatest<any>(CART_BUY_NOW_REQUEST, watchCartBuyNowSaga),
];
