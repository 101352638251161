/* eslint-disable import/prefer-default-export */

export const AMPLITUDE_EVENT_NAME = {
  PRICE: {
    PRICE_LIST_VIEWED: 'price_price-list_viewed',
  },
  CALCULATOR: {
    HOME_CALCULATOR_BT_CLICKED: 'calculator_home-calculator-bt_clicked',
    COLLECTION_CALCULATOR_BT_CLICKED: 'calculator_collection-calculator-bt_clicked',
    LAUNDRY_CALCULATION_LIST_VIEWED: 'calculator_laundry-calculation-list_viewed',
    LAUNDRY_CALCULATION_CART_COLLECTION_REQUEST_BT_CLICKED:
      'calculator_laundry-calculation-cart-collection-request-bt_clicked',
  },
  STORE: {
    CROSSSELLING_DETAIL_VIEWED: 'store-crossselling-detail-viewed',
    CROSSSELLING_ORDER_BT_CLICKED: 'store-crossselling-order-bt-clicked',
    ORDER_DETAIL_VIEWED: 'store_order-detail_viewed',
    ORDER_BT_CLICKED: 'store_order-bt_clicked',
    ITEM_ADDED: 'store_item-added',
    ITEM_REMOVED: 'store_item-removed',
    ITEM_DETAIL_VIEWED: 'store_item-detail_viewed',
    CART_ITEM_SELECT_OPTION_VIEWED: 'store_cart-item-select-option_viewed',
  },
  LUMP_SUM_PAYMENT: {
    PLAN_DETAIL_VIEWED: 'lumpsum_plan-detail_viewed',
    PLAN_SELECT_BT_CLICKED: 'lumpsum_plan-select-bt_clicked',
    SUBSCRIPTION_BT_CLICKED: 'lumpsum_subscription-bt_clicked',
    PAUSE_BT_CLICKED: 'lumpsum_pause-bt_clicked',
    UNPAUSE_BT_CLICKED: 'lumpsum_unpause-bt_clicked',
    UNSUBSCRIPTIONL_BT_CLICKED: 'lumpsum_unsubcription-bt_clicked',
    UNSUNBSCRIPTION_CANCEL_BT_CLICKED: 'lumpsum_unsubcription-cancel-bt_clicked',
  },
};
