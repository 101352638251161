import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import produce from 'immer';

import type { TRootState } from '~/redux/store';

const SET_USERINFO = 'SET_USERINFO';

export const setUserInfo = createAction<TUserState>(SET_USERINFO);

type TUserState = {
  userId: number | string | null;
};

const initialState: TUserState = {
  userId: null,
};

export const reducer = handleActions<TUserState, TUserState>(
  {
    [SET_USERINFO]: (state, { payload }: { payload: TUserState }) =>
      produce(state, (draft) => {
        draft.userId = payload.userId;
      }),
  },
  initialState
);

const userInfoSelector = (state: TRootState) => state.user.userInfo;

export const selectUserInfo = createSelector(userInfoSelector, (userInfo) => {
  return userInfo;
});
